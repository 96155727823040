$enable-shadows: true;
$enable-gradients: false;
$gray-900: #00386c;
$body-color: $gray-900;
$black: #000000;
$dark: #001A33;
$gray-800: #1f517e;
$gray-700: #3d6991;
$gray-600: #5c82a3;
$gray-500: #7b9ab6;
$gray-400: #99b3c8;
$gray-200: #d6e4ed;
$gray-100: #F5FCFF;
$gray-300: #b8cbda;
$blue-900: #CEEFFF;
$red: #FF4233;
$primary: #00386C;
$secondary: #00AAE4;
$success: #00CAA7;
$info: #007EFF;
$warning: #FFC100;
$white: #fff;
$body-bg: $white;
$link-color: $primary;
$link-hover-color: $secondary;
$navbar-padding-y: .5rem;
$navbar-nav-link-padding-x: 1rem;
$font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 600;
$font-family-monospace: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
$input-btn-font-family: null;
$btn-font-family: Inter;
$small-font-size: 0.875em;
$sub-sup-font-size: 0.75em;
$input-font-family: $font-family-monospace;
$navbar-padding-x: .5rem;
$h1-font-size: 2.5rem;
$h2-font-size: 1.75rem;
$h4-font-size: 1.125rem;
$h3-font-size: 1.5rem;
$h5-font-size: 1rem;
$h6-font-size: .875rem;
